import img_grande_large from '../images/vitrines/Grande_large.png';
import img_colonne_bijoux from '../images/vitrines/colonne_bijoux.png';
import img_colonne_vitree from '../images/vitrines/Colonne_vitree.png';
import img_comptoir from '../images/vitrines/Comptoire_plus_colonne_bijoux.png';


import sous_img_1 from '../images/vitrines/lampe.jpg';
import sous_img_2 from '../images/vitrines/etagere.jpg';
import sous_img_3 from '../images/vitrines/pieds.jpg';

export const liste_vitrines = [
  {
	nom: "Grande large",
	
	description:"<p>Portes coulissantes avec serrure</p><p>4 étagères (ajout sur demande sans frais) facilement réglables grâce à un système de crémaillères et de supports</p><p>Spots orientables</p><p>Pieds réglables</p><p><u>Existe avec caisson</u></p>",
	hauteur:"200",
	largeur:"100",
	profondeur:"50",
	lumiere:"<p>Un éclairage 100% LED avec 5 spots plafonniers orientables et 8 spots sur les côtés et un choix températures de couleurs : jaune (2700k), blanc (4000k) ou ultra-blanc (6000k).</p>",
	consommation:"95",
	photos:[img_grande_large, sous_img_1, sous_img_2, sous_img_3],
	prix:"210 €",
	slug:"grande-large",
	mw:"300px",
  },
  {
	nom: "Colonne bijoux",
	
	description:"<p>Livrée avec ou sans étagère (ajout sur demande sans frais) facilement réglables grâce à un système de crémaillères et de supports</p><p>Spots orientables</p><p>Portes battantes avec serrure</p><p>Pieds réglables</p>",
	hauteur:"200",
	largeur:"60",
	profondeur:"60",
	lumiere:"<p>Un éclairage 100% LED avec 4 spots plafonniers orientables et un choix températures de couleurs : jaune (2700k), blanc (4000k) ou ultra-blanc (6000k).</p>",
	consommation:"28",
	photos:[img_colonne_bijoux],
	prix:"200 €",
	slug:"colonne-bijoux",
	mw:"300px",
  },
  {
	nom: "Colonne vitrée",
	
	description:"<p>4 étagères (ajout sur demande sans frais) facilement réglables grâce à un système de crémaillères et de supports</p><p>Spots orientables</p><p>Porte battante avec serrure</p><p>Pieds réglables</p>",
	hauteur:"200",
	largeur:"60",
	profondeur:"60",
	lumiere:"<p>Un éclairage 100% LED avec 4 spots plafonniers orientables et 8 spots sur les côtés et un choix températures de couleurs : jaune (2700k), blanc (4000k) ou ultra-blanc (6000k).</p>",
	consommation:"84",
	photos:[img_colonne_vitree],
	prix:"200 €",
	slug:"colonne-vitree",
	mw:"300px",
  },
  {
	nom: "Comptoir",
	
	description:"<p><u>Disponible avec ou sans caisson</u></p><p>Portes coulissantes avec serrure</p>",
	hauteur:"85",
	largeur:"100",
	profondeur:"60",
	lumiere:"<p>Un éclairage par bandeau LED avec choix des température de couleurs par télécommande : jaune (2700k), blanc (4000k) ou ultra-blanc (6000k).</p>",
	consommation:"5",
	photos:[img_comptoir],
	prix:"180 €",
	slug:"comptoir",
	mw:"100%",
  }
];

export const tel = "+33 (0)6 17 89 85 48";
export const tel_lien = "+33617898548";
export const mail = "contact@lvdv.fr";
export const adr_1 = "37, rue d'Amsterdam";
export const adr_2 = "75008 Paris";

export const INSTAGRAM_ID = "787132";

export const LIEN_INSTAGRAM = "https://www.instagram.com/lesvitrinesdevalerie/?hl=fr";
export const LIEN_FACEBOOK = "https://www.facebook.com/people/Les-Vitrines-de-Val%C3%A9rie/100088530236864/";